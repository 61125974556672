import React from "react";
import styled from "styled-components";


function Background() {
  return <Background1></Background1>;
}

const Background1 = styled.div`
  position: absolute;
  width: 1440px;
  height: 800px;
  top: 0;
  left: 0;
  background-color: var(--onyx);
`;

export default Background;
