import { css } from "styled-components";

export const ValignTextMiddle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InterNormalGray16px = css`
  color: var(--gray);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l2);
  font-weight: 400;
  font-style: normal;
`;

export const HelveticaneueBoldLicorice64px = css`
  color: var(--licorice);
  font-family: var(--font-family-helveticaneue-bold);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  font-style: normal;
`;

export const InterNormalWhite16px = css`
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l2);
  font-weight: 400;
  font-style: normal;
`;

export const InterBoldThunder24px = css`
  color: var(--thunder);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m2);
  font-weight: 700;
  font-style: normal;
`;

export const InterNormalWhite14px = css`
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs2);
  font-weight: 400;
  font-style: normal;
`;

export const HelveticaneueMediumWhite30px = css`
  color: var(--white);
  font-family: var(--font-family-helveticaneue-medium);
  font-size: var(--font-size-xxl);
  font-weight: 500;
  font-style: normal;
`;

export const HelveticaneueBoldThunder64px = css`
  color: var(--thunder);
  font-family: var(--font-family-helveticaneue-bold);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  font-style: normal;
`;

export const HelveticaneueMediumMineShaft30px = css`
  color: var(--mine-shaft);
  font-family: var(--font-family-helveticaneue-medium);
  font-size: var(--font-size-xxl);
  font-weight: 500;
  font-style: normal;
`;

export const HelveticaneueBoldWhite24px = css`
  color: var(--white);
  font-family: var(--font-family-helveticaneue-bold);
  font-size: var(--font-size-m2);
  font-weight: 700;
  font-style: normal;
`;

export const InterNormalMercury16px = css`
  color: var(--mercury);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l2);
  font-weight: 400;
  font-style: normal;
`;

export const HelveticaneueBoldMineShaft24px = css`
  color: var(--mine-shaft-2);
  font-family: var(--font-family-helveticaneue-bold);
  font-size: var(--font-size-m2);
  font-weight: 700;
  font-style: normal;
`;

export const InterNormalGraniteGray16px = css`
  color: var(--granite-gray);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l2);
  font-weight: 400;
  font-style: normal;
`;

export const InterBoldThunder16px = css`
  color: var(--thunder);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l2);
  font-weight: 700;
  font-style: normal;
`;

export const HelveticaneueBoldWhite64px = css`
  color: var(--white);
  font-family: var(--font-family-helveticaneue-bold);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  font-style: normal;
`;

export const InterBoldInternationalOrange16px = css`
  color: var(--international-orange);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l2);
  font-weight: 700;
  font-style: normal;
`;

export const ProximanovaNormalInternationalOrang = css`
  color: var(--international-orange);
  font-family: var(--font-family-proxima_nova-semibold);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
`;

export const Border1pxWhite = css`
  border: 1px solid var(--white);
`;
