import React from "react";
import styled from "styled-components";
import { InterNormalGray16px, InterBoldThunder24px } from "../../styledMixins";


function Service(props) {
  const { childFocused, weReWorkingToPro, className } = props;

  return (
    <Service1 className={`service ${className || ""}`}>
      <IconPlaceholder className="icon-placeholder-1"></IconPlaceholder>
      <ChildFocused className="child-focused">{childFocused}</ChildFocused>
      <WereWorkingToPro className="were-working-to-pro">{weReWorkingToPro}</WereWorkingToPro>
    </Service1>
  );
}

const Service1 = styled.div`
  width: 404px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  padding: 32px;
  align-items: flex-start;
  min-height: 312px;

  &.service.service-1 {
    padding: 0 32px;
    margin-top: unset;
  }

  &.service.service-3 {
    margin-top: unset;
  }
`;

const IconPlaceholder = styled.div`
  width: 80px;
  height: 80px;
  background-color: var(--pot-pourri);
  border-radius: 16px;
`;

const ChildFocused = styled.div`
  ${InterBoldThunder24px}
  min-height: 32px;
  margin-top: 24px;
  letter-spacing: 0;
  line-height: 32px;
  white-space: nowrap;
`;

const WereWorkingToPro = styled.p`
  ${InterNormalGray16px}
  width: 274px;
  min-height: 96px;
  margin-top: 16px;
  letter-spacing: 0;
  line-height: 32px;
`;

const IconPlaceholder1 = styled.div`
  .service.service-1 & {
    margin-top: 32px;
  }
`;

const WereSupportingNew = styled.p`
  ${InterNormalGray16px}

  .service.service-1  & {
    width: 265px;
    min-height: 128px;
  }
`;

const EmpoweringTheFamil = styled.p`
  ${InterNormalGray16px}

  .service.service-2  & {
    width: 336px;
  }
`;

const TheBestWayToHelp = styled.p`
  ${InterNormalGray16px}

  .service.service-3  & {
    width: 225px;
  }
`;

const X26MillionChildren = styled.p`
  ${InterNormalGray16px}

  .service.service-4  & {
    width: 325px;
  }
`;

export default Service;
