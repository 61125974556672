
import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./components/Home";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/:path(|home)">
          <Home {...homeData} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
const service1Data = {
    childFocused: "Child Focused",
    weReWorkingToPro: "We're working to provide children and families in Kandahar with life-saving health and nutrition services",
};

const service2Data = {
    childFocused: "Relocation Sponsoring",
    weReWorkingToPro: "We're supporting newly arriving Afghan refugee families seeking safety in America",
    className: "service-1",
};

const service3Data = {
    childFocused: "Economic Empowerement",
    weReWorkingToPro: "Empowering the families especially women and children in Afganisthan to give them economic freedom",
    className: "service-2",
};

const service4Data = {
    childFocused: "Community Based",
    weReWorkingToPro: "The best way to help a child is to sustainably grow their community.",
    className: "service-3",
};

const service5Data = {
    childFocused: "Education",
    weReWorkingToPro: "2.6 million children are out of school. Educating Refugees and empowering them through modren means of education.",
    className: "service-4",
};

const homeData = {
    imagePlaceholder1: "https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/image-placeholder@2x.png",
    imagePlaceholder2: "https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/image-placeholder-1@2x.png",
    imagePlaceholder3: "https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/image-placeholder-2@2x.png",
    donate1: "Donate",
    tacklingTheWorlds1: "Tackling the World’s Largest Humanitarian Crisis",
    weAreHelpingTheH: "We are helping the high risk and in high need families to eat and survive.",
    donate2: "DONATE",
    place1: "Home",
    about: "About",
    impact: "Impact",
    blog: "Blog",
    place2: "Contact",
    spanText1: "V",
    spanText2: "e",
    spanText3: "ctor Global",
    takeAction1: "Take Action",
    aboutVectorGlobal: "ABOUT VECTOR GLOBAL",
    spanText4: "Sta",
    spanText5: "r",
    spanText6: "vation could kill more Afghans than last twen",
    spanText7: "t",
    spanText8: "y years of war",
    actingAsAHumanita: "Acting as a humanitarian operation by citizens of the world, our current efforts are focused on immediate rescue action plans and working with international organizations to place, care, and safeguard our Afghan families, especially the Women, Children, youth activist's, Lawyer's, Doctors, Engineers, Judges, Journalist's, and many other key Afghan players within the Afghan Government, Elite Commando Units, and select intelligence support cells. The Women, girls, Journalists, prosecutors, and their family members are the most in danger and documented by the Taliban to be methodically murdered, summarily executed.",
    imagePlaceholder4: "https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/image-placeholder-3@1x.png",
    whatWeDo: "WHAT WE DO?",
    findingSolutionsTo: "Finding Solutions to change the future of Aganisthan",
    vectorGlobalSoluti: "Vector Global Solutions is building relationships in Canada. Earning the right to be heard in Afghanistan. Planning and working alongside local leaders in Canada. Finding solutions to change the future of Afghan Women, girls, children, Journalists, lawyers, Judges, educated people, humanitarian activists and their families suffering in torture and fear by relocating them with the help of our kind hearted sponsors.",
    takeAction2: "Take Action",
    imagePlaceholder5: "https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/image-placeholder-4@1x.png",
    ourActions: "Our Actions",
    hungerFoodSecurity: "Hunger & Food Security",
    battlingHungerAnd: "Battling hunger and providing food security is a key factor to tackle the humanitarian Crrisis",
    ourWork: "Our Work",
    project1: "https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/container-6@1x.png",
    thousandsOfAfghan1: "Thousands of Afghan refugees and evacuees need our help to settle into their new lives in the US.",
    relocationOfRefugees: "Relocation of Refugees",
    activeButton1: "See All",
    project2: "https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/container-5@2x.png",
    thousandsOfAfghan2: "Thousands of Afghan refugees and evacuees need our help to settle into their new lives in the US.",
    container1: "https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/container-4@2x.png",
    container2: "https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/container-3@2x.png",
    cta: "https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/background@1x.png",
    theWayYouHelpUs: "The Way You Help Us 🍀",
    // loremIpsumDolorSi: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat facilisis quam volutpat turpis. Felis mi, ultrices ornare duis.",
    activeButton2: "Donate",
    activeButton3: "Take Action",
    spanText9: "Our ",
    spanText10: "L",
    spanText11: "atest Blog",
    container3: "https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/container-2@2x.png",
    howYouCanHelpChi1: "How You Can Help Children in Afghanistan",
    youCanCreateChang: "You can create change that lasts a lifetime for children in Afghanistan – in so many ways.Give a meaningful gift that will help transform children’s lives and futures in Afghanistan and beyond.",
    container4: "https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/container-1@2x.png",
    sendHopeEveryMont1: "Send Hope Every Month to Strong Refugees",
    moreThan9Million: "More than 9 million Afghans have been forced to flee their homes and communities due to insecurity and violence — an estimated 700,000 Afghans have been newly displaced inside their country since the beginning of 2021.",
    activeButton4: "See All",
    container5: "https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/container@2x.png",
    maximizeYourGiftToRefugees: "Maximize your gift to refugees",
    usaForUnhcrHelps: "Vector Global Solutions, Canada  helps and protects refugees and people displaced by violence, conflict and persecution. Supporting Vector Global Solutions – and its partners, your gift provides lifesaving essentials including shelter, water, food, safety and protection..",
    tacklingTheWorlds2: "Tackling the World’s Largest Humanitarian Crisis",
    getInTouch: "Get in Touch",
    address: <>535 Yates Street, Victoria, British Columbia<br />V8W 2Z6</>,
    ourstudioHelloCom: "info@vectorglobalsolutions.org",
  
    recentPost: "Recent Post",
    sendHopeEveryMont2: "Send Hope Every Month to Strong Refugees",
    howYouCanHelpChi2: "How You Can Help Children in Afghanistan",
    joinANewsletter: "Join a Newsletter",
    inputYourTextInHere: "Enter Your Email",
    textInput: "Your Email",
    activeButton5: "Subscribe",
    spanText12: "V",
    spanText13: "e",
    spanText14: "ctor Global",
    service1Props: service1Data,
    service2Props: service2Data,
    service3Props: service3Data,
    service4Props: service4Data,
    service5Props: service5Data,
};

