import React from "react";
import Background from "../Background";
import Service from "../Service";
import styled from "styled-components";
import {
  InterNormalGray16px,
  InterNormalWhite14px,
  InterNormalWhite16px,
  HelveticaneueBoldMineShaft24px,
  InterNormalGraniteGray16px,
  HelveticaneueMediumMineShaft30px,
  HelveticaneueBoldThunder64px,
  InterNormalMercury16px,
  HelveticaneueBoldLicorice64px,
  InterBoldInternationalOrange16px,
  HelveticaneueMediumWhite30px,
  Border1pxWhite,
  HelveticaneueBoldWhite64px,
  ProximanovaNormalInternationalOrang,
  HelveticaneueBoldWhite24px,
  InterBoldThunder16px,
  ValignTextMiddle,
} from "../../styledMixins";
import "./Home.css";

function Home(props) {
  const {
    imagePlaceholder1,
    imagePlaceholder2,
    imagePlaceholder3,
    donate1,
    tacklingTheWorlds1,
    weAreHelpingTheH,
    donate2,
    place1,
    about,
    impact,
    blog,
    place2,
    spanText1,
    spanText2,
    spanText3,
    takeAction1,
    aboutVectorGlobal,
    spanText4,
    spanText5,
    spanText6,
    spanText7,
    spanText8,
    actingAsAHumanita,
    imagePlaceholder4,
    whatWeDo,
    findingSolutionsTo,
    vectorGlobalSoluti,
    takeAction2,
    imagePlaceholder5,
    ourActions,
    hungerFoodSecurity,
    battlingHungerAnd,
    ourWork,
    project1,
    thousandsOfAfghan1,
    relocationOfRefugees,
    activeButton1,
    project2,
    thousandsOfAfghan2,
    container1,
    container2,
    cta,
    theWayYouHelpUs,
    loremIpsumDolorSi,
    activeButton2,
    activeButton3,
    spanText9,
    spanText10,
    spanText11,
    container3,
    howYouCanHelpChi1,
    youCanCreateChang,
    container4,
    sendHopeEveryMont1,
    moreThan9Million,
    activeButton4,
    container5,
    maximizeYourGiftToRefugees,
    usaForUnhcrHelps,
    tacklingTheWorlds2,
    getInTouch,
    address,
    ourstudioHelloCom,
    phone2,
    recentPost,
    sendHopeEveryMont2,
    howYouCanHelpChi2,
    joinANewsletter,
    inputYourTextInHere,
    textInput,
    activeButton5,
    spanText12,
    spanText13,
    spanText14,
    service1Props,
    service2Props,
    service3Props,
    service4Props,
    service5Props,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="home screen">
        <OverlapGroup9>
          <OverlapGroup4>
            <Background />
            <ImagePlaceholder src={imagePlaceholder1} />
            <ImagePlaceholder1 src={imagePlaceholder2} />
            <ImagePlaceholder2 src={imagePlaceholder3} />
            <Button>
              <Donate>{donate1}</Donate>
            </Button>
            <TacklingTheWorlds>{tacklingTheWorlds1}</TacklingTheWorlds>
            <Bg></Bg>
            <WeAreHelpingTheH>{weAreHelpingTheH}</WeAreHelpingTheH>
          </OverlapGroup4>
          <Button1>
            <DONATE>{donate2}</DONATE>
          </Button1>
          <Menu>
            <ActiveMenu>
              <Place>{place1}</Place>
              <ActiveMenu1></ActiveMenu1>
            </ActiveMenu>
            <About>{about}</About>
            <Impact>{impact}</Impact>
            <Blog>{blog}</Blog>
            <Place1>{place2}</Place1>
          </Menu>
          <OverlapGroup5>
            <VectorGlobal>
              <Span0>{spanText1}</Span0>
              <Span1>{spanText2}</Span1>
              <Span0>{spanText3}</Span0>
            </VectorGlobal>
            <Ellipse1></Ellipse1>
          </OverlapGroup5>
        </OverlapGroup9>
        <Group4>
          <OverlapGroup6>
            <TakeAction>{takeAction1}</TakeAction>
            <Group2>
              <FlexRow>
                <ABOUTVECTORGLOBAL>{aboutVectorGlobal}</ABOUTVECTORGLOBAL>
                <Line48 src="https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/line-48@2x.svg" />
              </FlexRow>
              <Value>
                <StarvationCouldKil>
                  <Span01>{spanText4}</Span01>
                  <Span11>{spanText5}</Span11>
                  <Span01>{spanText6}</Span01>
                  <Span3>{spanText7}</Span3>
                  <Span01>{spanText8}</Span01>
                </StarvationCouldKil>
                <ActingAsAHumanita>{actingAsAHumanita}</ActingAsAHumanita>
                <ImagePlaceholder3 src={imagePlaceholder4} />
              </Value>
            </Group2>
          </OverlapGroup6>
        </Group4>
        <Group3>
          <FlexRow1>
            <WHATWEDO>{whatWeDo}</WHATWEDO>
            <Line49 src="https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/line-48@2x.svg" />
          </FlexRow1>
          <FindingSolutionsTo>{findingSolutionsTo}</FindingSolutionsTo>
          <VectorGlobalSoluti>{vectorGlobalSoluti}</VectorGlobalSoluti>
          <TakeAction1>{takeAction2}</TakeAction1>
          <ImagePlaceholder4 src={imagePlaceholder5} />
        </Group3>
        <OverlapGroup8>
          <OverlapGroup3>
            <OurActions>{ourActions}</OurActions>
            <FlexRow2>
              <FlexCol>
                <OverlapGroup>
                  <IconPlaceholder></IconPlaceholder>
                  <HungerFoodSecurity>{hungerFoodSecurity}</HungerFoodSecurity>
                  <BattlingHungerAnd>{battlingHungerAnd}</BattlingHungerAnd>
                </OverlapGroup>
                <Service childFocused={service1Props.childFocused} weReWorkingToPro={service1Props.weReWorkingToPro} />
              </FlexCol>
              <ServiceContainer>
                <Service
                  childFocused={service2Props.childFocused}
                  weReWorkingToPro={service2Props.weReWorkingToPro}
                  className={service2Props.className}
                />
                <Service
                  childFocused={service3Props.childFocused}
                  weReWorkingToPro={service3Props.weReWorkingToPro}
                  className={service3Props.className}
                />
              </ServiceContainer>
              <ServiceContainer>
                <Service
                  childFocused={service4Props.childFocused}
                  weReWorkingToPro={service4Props.weReWorkingToPro}
                  className={service4Props.className}
                />
                <Service
                  childFocused={service5Props.childFocused}
                  weReWorkingToPro={service5Props.weReWorkingToPro}
                  className={service5Props.className}
                />
              </ServiceContainer>
            </FlexRow2>
          </OverlapGroup3>
          <Vector src="https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/vector@2x.svg" />
          <Group src="https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/group@2x.svg" />
          <Group1 src="https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/group-1@2x.svg" />
          <Group5 src="https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/group-2@2x.svg" />
          <Group6 src="https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/group-3@2x.svg" />
          <Group7 src="https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/group-4@2x.svg" />
        </OverlapGroup8>
        <Project>
          <FlexCol1>
            <OurWork>{ourWork}</OurWork>
            <Project1 style={{ backgroundImage: `url(${project1})` }}>
              <OverlapGroup1>
                <OfContainer>
                  <ThousandsOfAfghan>{thousandsOfAfghan1}</ThousandsOfAfghan>
                  <RelocationOfRefugees>{relocationOfRefugees}</RelocationOfRefugees>
                </OfContainer>
                <ArrowChevronRight src="https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/arrow-chevron-right-1@2x.svg" />
              </OverlapGroup1>
            </Project1>
          </FlexCol1>
          <FlexCol2>
            <Button2>
              <ActiveButton>{activeButton1}</ActiveButton>
            </Button2>
            <Project2 style={{ backgroundImage: `url(${project2})` }}>
              <OverlapGroup2>
                <ThousandsOfAfghan1>{thousandsOfAfghan2}</ThousandsOfAfghan1>
                <ArrowChevronRight1 src="https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/arrow-chevron-right@2x.svg" />
              </OverlapGroup2>
            </Project2>
            <Container src={container1} />
            <Container src={container2} />
          </FlexCol2>
        </Project>
        <CTA style={{ backgroundImage: `url(${cta})` }}>
          <OverlapGroup7>
            <TheWayYouHelpUs>{theWayYouHelpUs}</TheWayYouHelpUs>
            <LoremIpsumDolorSi>{loremIpsumDolorSi}</LoremIpsumDolorSi>
            <ButtonContainer>
              <Button3>
                <ActiveButton1>{activeButton2}</ActiveButton1>
              </Button3>
              <Button4>
                <ActiveButton2>{activeButton3}</ActiveButton2>
              </Button4>
            </ButtonContainer>
          </OverlapGroup7>
        </CTA>
        <Blog1>
          <FlexRow3>
            <FlexCol3>
              <OurLatestBlog>
                <Span02>{spanText9}</Span02>
                <Span12>{spanText10}</Span12>
                <Span02>{spanText11}</Span02>
              </OurLatestBlog>
              <BlogCardContainer>
                <BlogCard>
                  <Container1 src={container3} />
                  <HowYouCanHelpChi>{howYouCanHelpChi1}</HowYouCanHelpChi>
                  <YouCanCreateChang>{youCanCreateChang}</YouCanCreateChang>
                </BlogCard>
                <BlogCard1>
                  <Container2 src={container4} />
                  <SendHopeEveryMont>{sendHopeEveryMont1}</SendHopeEveryMont>
                  <MoreThan9Million>{moreThan9Million}</MoreThan9Million>
                </BlogCard1>
              </BlogCardContainer>
            </FlexCol3>
            <FlexCol4>
              <Button2>
                <ActiveButton>{activeButton4}</ActiveButton>
              </Button2>
              <BlogCard2>
                <Container3 src={container5} />
                <MaximizeYourGiftToRefugees>{maximizeYourGiftToRefugees}</MaximizeYourGiftToRefugees>
                <USAForUNHCRHelps>{usaForUnhcrHelps}</USAForUNHCRHelps>
              </BlogCard2>
            </FlexCol4>
          </FlexRow3>
        </Blog1>
        <OverlapGroup10>
          <Footer>
            <TacklingTheWorlds1>{tacklingTheWorlds2}</TacklingTheWorlds1>
            <Section2>
              <GetInTouch>{getInTouch}</GetInTouch>
              <Address>
                <LocationMapMarker4 src="https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/location-map-marker-4@2x.svg" />
                <Address1>{address}</Address1>
              </Address>
              <Email>
                <LocationMapMarker4 src="https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/mail@2x.svg" />
                <Ourstudiohellocom>{ourstudioHelloCom}</Ourstudiohellocom>
              </Email>
              {/* <Phone>
                <LocationMapMarker4 src="https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/phone@2x.svg" />
                <Phone1>{phone2}</Phone1>
              </Phone> */}
            </Section2>
            <Section3>
              <GetInTouch>{recentPost}</GetInTouch>
              <BlogContainer>
                <Blog2>
                  <Image></Image>
                  <SendHopeEveryMont1>{sendHopeEveryMont2}</SendHopeEveryMont1>
                </Blog2>
                <Blog3>
                  <Image></Image>
                  <HowYouCanHelpChi1>{howYouCanHelpChi2}</HowYouCanHelpChi1>
                </Blog3>
              </BlogContainer>
            </Section3>
            <Section4>
              <GetInTouch>{joinANewsletter}</GetInTouch>
              <OverlapGroup11>
                <Form>
                  <InputYourTextInHere>{inputYourTextInHere}</InputYourTextInHere>
                </Form>
                <TextInput>{textInput}</TextInput>
              </OverlapGroup11>
              <Button5>
                <ActiveButton3>{activeButton5}</ActiveButton3>
              </Button5>
            </Section4>
          </Footer>
          <OverlapGroup71>
            <VectorGlobal1>
              <Span03>{spanText12}</Span03>
              <Span13>{spanText13}</Span13>
              <Span03>{spanText14}</Span03>
            </VectorGlobal1>
            <Ellipse1></Ellipse1>
          </OverlapGroup71>
        </OverlapGroup10>
      </div>
    </div>
  );
}

const OverlapGroup9 = styled.div`
  width: 1476px;
  height: 800px;
  position: relative;
  align-self: flex-start;
`;

const OverlapGroup4 = styled.div`
  position: absolute;
  width: 1472px;
  height: 800px;
  top: 0;
  left: 0;
`;

const ImagePlaceholder = styled.img`
  position: absolute;
  width: 200px;
  height: 200px;
  top: 560px;
  left: 1032px;
  object-fit: cover;
`;

const ImagePlaceholder1 = styled.img`
  position: absolute;
  width: 240px;
  height: 240px;
  top: 384px;
  left: 1232px;
  object-fit: cover;
`;

const ImagePlaceholder2 = styled.img`
  position: absolute;
  width: 400px;
  height: 400px;
  top: 144px;
  left: 832px;
  object-fit: cover;
`;

const Button = styled.div`
  position: absolute;
  height: 48px;
  top: 568px;
  left: 104px;
  display: flex;
  padding: 0 52px;
  align-items: center;
  min-width: 160px;
  background-color: var(--international-orange);
  border-radius: 8px;
`;

const Donate = styled.div`
  min-height: 24px;
  min-width: 56px;
  font-family: var(--font-family-inter);
  font-weight: 500;
  color: var(--white);
  font-size: 17px;
  letter-spacing: -0.68px;
  line-height: 24px;
  white-space: nowrap;
`;

const TacklingTheWorlds = styled.h1`
  ${HelveticaneueBoldWhite64px}
  position: absolute;
  width: 576px;
  top: 244px;
  left: 104px;
  letter-spacing: -1.92px;
  line-height: 67px;
`;

const Bg = styled.div`
  ${Border1pxWhite}
  position: absolute;
  width: 603px;
  height: 57px;
  top: 470px;
  left: 103px;
  background-color: var(--white-2);
  border-radius: 21px;
  backdrop-filter: blur(70px) brightness(100%);
  -webkit-backdrop-filter: blur(70px) brightness(100%);
`;

const WeAreHelpingTheH = styled.p`
  ${InterNormalWhite16px}
  position: absolute;
  width: 558px;
  top: 487px;
  left: 126px;
  letter-spacing: 0;
  line-height: 21.5px;
  white-space: nowrap;
`;

const Button1 = styled.div`
  position: absolute;
  height: 48px;
  top: 36px;
  left: 1288px;
  display: flex;
  padding: 0 32.5px;
  align-items: center;
  min-width: 120px;
  background-color: var(--international-orange);
  border-radius: 8px;
`;

const DONATE = styled.div`
  min-height: 24px;
  min-width: 55px;
  font-family: var(--font-family-roboto);
  font-weight: 600;
  color: var(--white);
  font-size: 15px;
  letter-spacing: -0.6px;
  line-height: 24px;
  white-space: nowrap;
`;

const Menu = styled.div`
  position: absolute;
  height: 32px;
  top: 44px;
  left: 792px;
  display: flex;
  align-items: flex-start;
  min-width: 430px;
`;

const ActiveMenu = styled.div`
  width: 45px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 32px;
`;

const Place = styled.div`
  ${InterNormalWhite16px}
  min-height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const GetInTouch = styled.div`
  ${InterBoldThunder16px}
  min-height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const ActiveMenu1 = styled.div`
  width: 40px;
  height: 4px;
  margin-top: 4px;
  background-color: var(--international-orange);
`;

const About = styled.div`
  ${InterNormalWhite16px}
  min-height: 24px;
  margin-left: 48px;
  min-width: 46px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const Impact = styled.div`
  ${InterNormalWhite16px}
  min-height: 24px;
  margin-left: 48px;
  min-width: 52px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const Blog = styled.div`
  ${InterNormalWhite16px}
  min-height: 24px;
  margin-left: 48px;
  min-width: 34px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const Place1 = styled.div`
  ${InterNormalWhite16px}
  min-height: 24px;
  margin-left: 48px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const OverlapGroup5 = styled.div`
  position: absolute;
  width: 257px;
  height: 57px;
  top: 32px;
  left: 55px;
`;

const VectorGlobal = styled.div`
  ${HelveticaneueMediumWhite30px}
  position: absolute;
  width: 257px;
  top: 0;
  left: 0;
  line-height: 67px;
  white-space: nowrap;
`;

const VectorGlobal1 = styled.div`
  ${HelveticaneueMediumMineShaft30px}
  position: absolute;
  width: 257px;
  top: 0;
  left: 0;
  line-height: 67px;
  white-space: nowrap;
`;

const Span0 = styled.span`
  ${HelveticaneueMediumWhite30px}
  letter-spacing: -0.36px;
`;

const Span03 = styled.span`
  ${HelveticaneueMediumMineShaft30px}
  letter-spacing: -0.36px;
`;

const Span1 = styled.span`
  ${HelveticaneueMediumWhite30px}
  letter-spacing: -0.18px;
`;

const Span13 = styled.span`
  ${HelveticaneueMediumMineShaft30px}
  letter-spacing: -0.18px;
`;

const Ellipse1 = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  top: 17px;
  left: 184px;
  background-color: var(--international-orange);
  border-radius: 16px;
`;

const Group4 = styled.div`
  margin-top: 116px;
  margin-left: 2px;
  display: flex;
  align-items: flex-start;
  min-width: 1234px;
`;

const OverlapGroup6 = styled.div`
  width: 1234px;
  height: 898px;
  position: relative;
`;

const TakeAction = styled.div`
  ${InterBoldInternationalOrange16px}
  position: absolute;
  top: 510px;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const Group2 = styled.div`
  position: absolute;
  width: 1234px;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 898px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  min-width: 381px;
`;

const ABOUTVECTORGLOBAL = styled.div`
  ${ValignTextMiddle}
  ${ProximanovaNormalInternationalOrang}
            height: 35px;
  min-width: 278px;
  opacity: 0.8;
  letter-spacing: 3.23px;
  line-height: 34.5px;
  white-space: nowrap;
`;

const Line48 = styled.img`
  width: 89px;
  height: 4px;
  margin-left: 14px;
  margin-top: 1px;
`;

const Value = styled.div`
  width: 1236px;
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 837px;
`;

const StarvationCouldKil = styled.div`
  ${HelveticaneueBoldLicorice64px}
  width: 997px;
  min-height: 134px;
  line-height: 67px;
`;

const Span01 = styled.span`
  ${HelveticaneueBoldLicorice64px}
  letter-spacing: -1.23px;
`;

const Span12 = styled.span`
  ${HelveticaneueBoldThunder64px}
  letter-spacing: -1.23px;
`;

const Span11 = styled.span`
  ${HelveticaneueBoldLicorice64px}
  letter-spacing: 0.41px;
`;

const Span3 = styled.span`
  ${HelveticaneueBoldLicorice64px}
  letter-spacing: 0;
`;

const ActingAsAHumanita = styled.p`
  ${InterNormalGray16px}
  width: 816px;
  min-height: 192px;
  margin-top: 28px;
  letter-spacing: 0;
  line-height: 32px;
`;

const ImagePlaceholder3 = styled.img`
  width: 1232px;
  height: 400px;
  margin-top: 83px;
  object-fit: cover;
`;

const Group3 = styled.div`
  width: 1240px;
  margin-top: 112px;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 853px;
`;

const FlexRow1 = styled.div`
  display: flex;
  align-items: center;
  min-width: 259px;
`;

const WHATWEDO = styled.div`
  ${ValignTextMiddle}
  ${ProximanovaNormalInternationalOrang}
            height: 35px;
  min-width: 160px;
  opacity: 0.8;
  letter-spacing: 3.23px;
  line-height: 34.5px;
  white-space: nowrap;
`;

const Line49 = styled.img`
  width: 89px;
  height: 4px;
  margin-left: 10px;
  margin-top: 1px;
`;

const FindingSolutionsTo = styled.div`
  ${HelveticaneueBoldLicorice64px}
  width: 920px;
  min-height: 134px;
  margin-top: 18px;
  letter-spacing: -2.56px;
  line-height: 67px;
`;

const VectorGlobalSoluti = styled.p`
  ${InterNormalGray16px}
  width: 816px;
  min-height: 128px;
  margin-top: 37px;
  letter-spacing: 0;
  line-height: 32px;
`;

const TakeAction1 = styled.div`
  ${InterBoldInternationalOrange16px}
  min-height: 24px;
  margin-top: 37px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const ImagePlaceholder4 = styled.img`
  width: 1232px;
  height: 400px;
  margin-top: 40px;
  object-fit: cover;
`;

const OverlapGroup8 = styled.div`
  width: 1442px;
  height: 1176px;
  position: relative;
  margin-top: 101px;
  margin-left: 2px;
`;

const OverlapGroup3 = styled.div`
  position: absolute;
  width: 1440px;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 160px 100px;
  align-items: flex-start;
  min-height: 1176px;
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/background-3@1x.svg);
  background-size: 100% 100%;
`;

const OurActions = styled.div`
  ${HelveticaneueBoldLicorice64px}
  min-height: 67px;
  margin-left: 4px;
  letter-spacing: -2.56px;
  line-height: 67px;
  white-space: nowrap;
`;

const FlexRow2 = styled.div`
  height: 640px;
  align-self: flex-end;
  margin-top: 149px;
  display: flex;
  align-items: flex-start;
  min-width: 1236px;
`;

const FlexCol = styled.div`
  width: 404px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 640px;
`;

const OverlapGroup = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 32px;
  align-items: flex-start;
  min-height: 312px;
  background-color: var(--onyx);
  border-radius: 16px;
`;

const IconPlaceholder = styled.div`
  width: 80px;
  height: 80px;
  background-color: var(--international-orange);
  border-radius: 16px;
`;

const HungerFoodSecurity = styled.div`
  ${HelveticaneueBoldWhite24px}
  min-height: 32px;
  margin-top: 24px;
  letter-spacing: -0.72px;
  line-height: 32px;
  white-space: nowrap;
`;

const BattlingHungerAnd = styled.p`
  ${InterNormalMercury16px}
  width: 305px;
  min-height: 96px;
  margin-top: 16px;
  letter-spacing: 0;
  line-height: 32px;
`;

const ServiceContainer = styled.div`
  width: 404px;
  position: relative;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 640px;
`;

const Vector = styled.img`
  position: absolute;
  width: 43px;
  height: 43px;
  top: 428px;
  left: 570px;
`;

const Group = styled.img`
  position: absolute;
  width: 42px;
  height: 50px;
  top: 423px;
  left: 989px;
`;

const Group1 = styled.img`
  position: absolute;
  width: 26px;
  height: 34px;
  top: 759px;
  left: 164px;
`;

const Group5 = styled.img`
  position: absolute;
  width: 42px;
  height: 33px;
  top: 433px;
  left: 156px;
`;

const Group6 = styled.img`
  position: absolute;
  width: 42px;
  height: 28px;
  top: 763px;
  left: 575px;
`;

const Group7 = styled.img`
  position: absolute;
  width: 39px;
  height: 29px;
  top: 763px;
  left: 989px;
`;

const Project = styled.div`
  height: 870px;
  margin-top: 170px;
  margin-left: 2px;
  display: flex;
  align-items: flex-end;
  min-width: 1234px;
`;

const FlexCol1 = styled.div`
  width: 816px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 870px;
`;

const OurWork = styled.div`
  ${HelveticaneueBoldLicorice64px}
  min-height: 67px;
  letter-spacing: -2.56px;
  line-height: 67px;
  white-space: nowrap;
`;

const Project1 = styled.div`
  height: 752px;
  margin-top: 51px;
  display: flex;
  align-items: flex-end;
  min-width: 816px;
  background-size: cover;
  background-position: 50% 50%;
`;

const OverlapGroup1 = styled.div`
  height: 215px;
  display: flex;
  padding: 34px 33px;
  align-items: center;
  min-width: 736px;
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/container-1@1x.svg);
  background-size: 100% 100%;
`;

const OfContainer = styled.div`
  width: 375px;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 146px;
`;

const ThousandsOfAfghan = styled.div`
  ${HelveticaneueBoldWhite24px}
  width: 375px;
  min-height: 96px;
  letter-spacing: -0.96px;
  line-height: 32px;
`;

const RelocationOfRefugees = styled.div`
  ${InterNormalMercury16px}
  width: 224px;
  min-height: 43px;
  margin-top: 7px;
  letter-spacing: 0;
  line-height: 32px;
`;

const ArrowChevronRight = styled.img`
  width: 40px;
  height: 55px;
  margin-left: 248px;
`;

const FlexCol2 = styled.div`
  width: 400px;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 860px;
`;

const Button2 = styled.div`
  height: 48px;
  display: flex;
  padding: 0 57px;
  align-items: center;
  min-width: 160px;
  background-color: var(--international-orange);
  border-radius: 8px;
`;

const ActiveButton = styled.div`
  ${InterNormalWhite14px}
  min-height: 24px;
  min-width: 46px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const Project2 = styled.div`
  height: 240px;
  margin-top: 60px;
  display: flex;
  align-items: flex-end;
  min-width: 400px;
  background-size: cover;
  background-position: 50% 50%;
`;

const OverlapGroup2 = styled.div`
  height: 152px;
  display: flex;
  padding: 0 16px;
  justify-content: flex-end;
  align-items: center;
  min-width: 400px;
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/container@2x.svg);
  background-size: 100% 100%;
`;

const ThousandsOfAfghan1 = styled.div`
  ${HelveticaneueBoldWhite24px}
  width: 291px;
  min-height: 108px;
  letter-spacing: -0.96px;
  line-height: 27px;
`;

const ArrowChevronRight1 = styled.img`
  width: 40px;
  height: 40px;
  margin-left: 35px;
`;

const Container = styled.img`
  width: 400px;
  height: 240px;
  margin-top: 16px;
  object-fit: cover;
`;

const CTA = styled.div`
  height: 672px;
  margin-top: 160px;
  display: flex;
  padding: 0 5px;
  align-items: flex-start;
  min-width: 1440px;
  background-size: cover;
  background-position: 50% 50%;
`;

const OverlapGroup7 = styled.div`
  width: 806px;
  display: flex;
  flex-direction: column;
  padding: 160px 99px;
  align-items: flex-start;
  min-height: 672px;
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/background-2@1x.svg);
  background-size: 100% 100%;
`;

const TheWayYouHelpUs = styled.div`
  ${HelveticaneueBoldWhite64px}
  width: 504px;
  min-height: 176px;
  letter-spacing: -3.2px;
  line-height: 88px;
`;

const LoremIpsumDolorSi = styled.p`
  ${InterNormalMercury16px}
  width: 504px;
  min-height: 64px;
  margin-top: 24px;
  letter-spacing: 0;
  line-height: 32px;
`;

const ButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: flex-start;
  min-width: 344px;
`;

const Button3 = styled.div`
  height: 48px;
  display: flex;
  padding: 0 56px;
  align-items: center;
  min-width: 160px;
  background-color: var(--international-orange);
  border-radius: 8px;
`;

const ActiveButton1 = styled.div`
  ${InterNormalWhite14px}
  min-height: 24px;
  min-width: 48px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const Button4 = styled.div`
  ${Border1pxWhite}
  height: 48px;
  margin-left: 24px;
  display: flex;
  padding: 0 41px;
  align-items: center;
  min-width: 160px;
  border-radius: 8px;
`;

const ActiveButton2 = styled.div`
  ${InterNormalWhite14px}
  min-height: 24px;
  min-width: 78px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const Blog1 = styled.div`
  margin-left: 2px;
  display: flex;
  align-items: flex-start;
  min-width: 1442px;
`;

const FlexRow3 = styled.div`
  height: 984px;
  display: flex;
  padding: 0 104px;
  align-items: center;
  min-width: 1440px;
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/background-1@1x.svg);
  background-size: 100% 100%;
`;

const FlexCol3 = styled.div`
  width: 816px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 664px;
`;

const OurLatestBlog = styled.div`
  ${HelveticaneueBoldThunder64px}
  min-height: 88px;
  line-height: 88px;
  white-space: nowrap;
`;

const Span02 = styled.span`
  ${HelveticaneueBoldThunder64px}
  letter-spacing: -2.05px;
`;

const BlogCardContainer = styled.div`
  height: 536px;
  margin-top: 40px;
  display: flex;
  align-items: flex-start;
  min-width: 816px;
`;

const BlogCard = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  align-items: center;
  min-height: 536px;
  background-color: var(--white);
  border-radius: 16px;
`;

const Container1 = styled.img`
  width: 368px;
  height: 242px;
  margin-top: 1px;
  object-fit: cover;
`;

const HowYouCanHelpChi = styled.div`
  ${HelveticaneueBoldMineShaft24px}
  width: 368px;
  min-height: 77px;
  margin-top: 16px;
  letter-spacing: -0.48px;
  line-height: 37px;
`;

const YouCanCreateChang = styled.p`
  ${InterNormalGraniteGray16px}
  width: 368px;
  min-height: 154px;
  margin-top: 16px;
  letter-spacing: 0;
  line-height: 32px;
`;

const BlogCard1 = styled.div`
  width: 400px;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  align-items: center;
  min-height: 536px;
  background-color: var(--white);
  border-radius: 16px;
`;

const Container2 = styled.img`
  width: 368px;
  height: 248px;
  margin-top: 6px;
  object-fit: cover;
`;

const SendHopeEveryMont = styled.div`
  ${HelveticaneueBoldMineShaft24px}
  width: 359px;
  min-height: 70px;
  margin-top: 16px;
  margin-right: 9px;
  letter-spacing: -0.72px;
  line-height: 36.5px;
`;

const MoreThan9Million = styled.p`
  ${InterNormalGraniteGray16px}
  width: 368px;
  min-height: 160px;
  margin-top: 16px;
  letter-spacing: 0;
  line-height: 32px;
`;

const FlexCol4 = styled.div`
  width: 400px;
  margin-left: 16px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 644px;
`;

const BlogCard2 = styled.div`
  width: 400px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  align-items: center;
  min-height: 536px;
  background-color: var(--white);
  border-radius: 16px;
`;

const Container3 = styled.img`
  width: 368px;
  height: 254px;
  margin-top: 6px;
  object-fit: cover;
`;

const MaximizeYourGiftToRefugees = styled.div`
  ${HelveticaneueBoldMineShaft24px}
  width: 368px;
  min-height: 32px;
  margin-top: 16px;
  letter-spacing: -0.48px;
  line-height: 32px;
  white-space: nowrap;
`;

const USAForUNHCRHelps = styled.p`
  ${InterNormalGraniteGray16px}
  width: 368px;
  min-height: 192px;
  margin-top: 16px;
  letter-spacing: 0;
  line-height: 32px;
`;

const OverlapGroup10 = styled.div`
  width: 1440px;
  height: 424px;
  position: relative;
`;

const Footer = styled.div`
  position: absolute;
  height: 424px;
  top: 0;
  left: 0;
  display: flex;
  padding: 0 102px;
  justify-content: flex-end;
  align-items: center;
  min-width: 1440px;
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/61fd401a9ce6f3aac719e759/releases/621463af4d93156569a396d9/img/background@1x.svg);
  background-size: 100% 100%;
`;

const TacklingTheWorlds1 = styled.p`
  ${InterNormalGray16px}
  width: 296px;
  min-height: 64px;
  margin-bottom: 40px;
  letter-spacing: 0;
  line-height: 32px;
`;

const Section2 = styled.div`
  width: 238px;
  margin-left: 52px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 224px;
`;

const Address = styled.div`
  height: 65px;
  margin-top: 24px;
  display: flex;
  align-items: flex-start;
  min-width: 238px;
`;

const LocationMapMarker4 = styled.img`
  width: 24px;
  height: 24px;
`;

const Address1 = styled.p`
  ${InterNormalGray16px}
  min-height: 64px;
  align-self: flex-end;
  margin-left: 16px;
  min-width: 196px;
  letter-spacing: 0;
  line-height: 32px;
`;

const Email = styled.div`
  height: 32px;
  margin-top: 23px;
  display: flex;
  align-items: center;
  min-width: 204px;
`;

const Ourstudiohellocom = styled.div`
  ${InterNormalGray16px}
  min-height: 32px;
  margin-left: 16px;
  min-width: 162px;
  letter-spacing: 0;
  line-height: 32px;
  white-space: nowrap;
`;

const Phone = styled.div`
  height: 32px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  min-width: 179px;
`;

const Phone1 = styled.div`
  ${InterNormalGray16px}
  min-height: 32px;
  margin-left: 16px;
  min-width: 137px;
  letter-spacing: 0;
  line-height: 32px;
  white-space: nowrap;
`;

const Section3 = styled.div`
  width: 250px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 264px;
`;

const BlogContainer = styled.div`
  width: 250px;
  height: 216px;
  position: relative;
  margin-top: 24px;
`;

const Blog2 = styled.div`
  position: absolute;
  height: 96px;
  top: 120px;
  left: 0;
  display: flex;
  align-items: flex-start;
  min-width: 225px;
`;

const Image = styled.div`
  width: 48px;
  height: 48px;
  background-color: var(--cararra);
`;

const SendHopeEveryMont1 = styled.p`
  ${InterNormalGray16px}
  width: 159px;
  min-height: 96px;
  margin-left: 16px;
  letter-spacing: 0;
  line-height: 32px;
`;

const Blog3 = styled.div`
  position: absolute;
  height: 128px;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  min-width: 250px;
`;

const HowYouCanHelpChi1 = styled.p`
  ${InterNormalGray16px}
  width: 184px;
  min-height: 128px;
  margin-left: 16px;
  letter-spacing: 0;
  line-height: 32px;
`;

const Section4 = styled.div`
  width: 298px;
  margin-left: 50px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 184px;
`;

const OverlapGroup11 = styled.div`
  width: 296px;
  height: 74px;
  position: relative;
  margin-top: 24px;
`;

const Form = styled.div`
  position: absolute;
  height: 51px;
  top: 22px;
  left: 0;
  display: flex;
  padding: 0 13.5px;
  justify-content: flex-end;
  align-items: center;
  min-width: 296px;
  background-color: var(--white);
  border-radius: 8px;
  border: 1px solid var(--celeste);
`;

const InputYourTextInHere = styled.div`
  width: 266px;
  min-height: 24px;
  font-family: var(--font-family-poppins);
  font-weight: 400;
  color: var(--celeste);
  font-size: var(--font-size-xs2);
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const TextInput = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  font-family: var(--font-family-poppins);
  font-weight: 400;
  color: var(--gray);
  font-size: var(--font-size-s2);
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const Button5 = styled.div`
  height: 48px;
  margin-top: 14px;
  display: flex;
  padding: 0 114.5px;
  align-items: center;
  min-width: 296px;
  background-color: var(--international-orange);
  border-radius: 8px;
`;

const ActiveButton3 = styled.div`
  ${InterNormalWhite14px}
  min-height: 24px;
  min-width: 67px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
`;

const OverlapGroup71 = styled.div`
  position: absolute;
  width: 257px;
  height: 57px;
  top: 63px;
  left: 104px;
`;

export default Home;
